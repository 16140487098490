(function(){'use strict';jQuery(document).ready(function($){function check_compare(value){for(var i=1;i<=8;i++){if(compare_list['p'+i]==value){return true
break;}}
return false;}
function readMore(string,maxWords){var strippedString=$("<p>"+string+"</p>").text().trim();var array=strippedString.split(" ");var string=array.splice(0,maxWords).join(" ");if(array.length>maxWords){string+="...";}
return string;}
function roadtip(element){var tipText=element.html();element.bind('mouseover',function(){if(jQuery('.roadtip').length==0){element.before('<span class="roadtip">'+tipText+'</span>');var tipWidth=jQuery('.roadtip').outerWidth();var tipPush=-(tipWidth/2-element.outerWidth()/2);jQuery('.roadtip').css('margin-left',tipPush);}});element.bind('mouseleave',function(){jQuery('.roadtip').remove();});}
function convert_currency(value,type){try{var newCurrency=Currency.currentCurrency;}catch(ex){var newCurrency='';}
try{var oldCurrency=shopCurrency;}catch(ex){var oldCurrency="USD";}
if(isNaN(value)){value=0.0;}
var cents=0.0;var oldFormat=Currency.moneyFormats['USD'][Currency.format]||'';var newFormat=Currency.moneyFormats[newCurrency][Currency.format]||'';var arr=[];arr=newFormat.split(" ");newFormat=arr[0];if(type=='format'){return Currency.formatMoney(value,newFormat);}
if(oldFormat.indexOf('amount_no_decimals')!==-1){cents=Currency.convert(parseInt(value,10)*100,oldCurrency,newCurrency);}
else if(oldCurrency==='JOD'||oldCurrency=='KWD'||oldCurrency=='BHD'){cents=Currency.convert(parseInt(value,10)/10,oldCurrency,newCurrency);}
else{cents=Currency.convert(parseInt(value,10),oldCurrency,newCurrency);}
if(type=='nosymbol'){return cents;}
var my_data=Currency.formatMoney(cents,newFormat);return my_data;}
function get_symbol(value){var str=value.split("");var sym='';for(var i=0;i<str.length;i++){if(isNaN(str[i])){sym+=str[i];}else{return sym;}}
return sym;}
function get_price_only(price){var price_convert=price.toString();var get_currencies=0;var $split=price_convert.split(';');if($split.length>1){get_currencies=(isNaN(parseInt(price_convert.substring(1,0))))?$split[1]:$split[0];}else{get_currencies=price_convert.replace(/[^0-9.]/g,'');}
get_currencies=$.trim(get_currencies);var check=get_currencies.substring(1,0);if(isNaN((check))){get_currencies=get_currencies.replace(check,'');}
return parseFloat(get_currencies);}
var max_price_slide=convert_currency(500000,'');var get_currencies=convert_currency(500000,'');var min_price_slide=convert_currency(1000,'');var limit_product=24;var $split=get_currencies.split(';');if($split.length>1){get_currencies=(isNaN(parseInt(get_currencies.substring(1,0))))?$split[0]+';':$split[1]+';';}else{get_currencies=(isNaN(parseInt(get_currencies.substring(1,0))))?get_symbol(get_currencies):get_currencies.substring(1,-1);}
$slider_filter=$('.slider-range-price').slider({range:true,min:0,max:get_price_only(max_price_slide),values:[get_price_only(min_price_slide),get_price_only(max_price_slide)],slide:function(event,ui){var max_price_slide=convert_currency(500000,'');var get_currencies=convert_currency(500000,'');var min_price_slide=convert_currency(1000,'');var $split=get_currencies.split(';');if($split.length>1){get_currencies=(isNaN(parseInt(get_currencies.substring(1,0))))?$split[0]+';':$split[1]+';';}else{get_currencies=(isNaN(parseInt(get_currencies.substring(1,0))))?get_symbol(get_currencies):get_currencies.substring(1,-1);}
var max_pr=get_currencies+''+ui.values[1];var min_pr=get_currencies+''+ui.values[0];$(".amount-range-price .from").html(min_pr);$(".amount-range-price .to").html(max_pr);},change:function(event,ui){var view_mode=($(".products-change-view").hasClass('active'))?'.products-grid-view':'.products-list-view';$("#loading").show(0);var max_price=ui.values[1];var min_price=ui.values[0];var check=0;var sum_product=0;var page=1;var count=0;$("#collection-product").empty();var html='';var view=$(".products-change-view.active").data('view');var has_product=false;$(".products-result-count").empty();$(".pagination").empty();$.each(json_collection_filter,function(index,elem){var current_price=convert_currency(elem.variants[0].price,'');current_price=get_price_only(current_price);if(current_price<=max_price&&current_price>=min_price){has_product=true;html=html+json_to_html(elem,view,check,page);sum_product++;count++;}
if(count>=limit_product){page++;count=0;}
check++;});html=html+"<input type='hidden' id='sum_for_pagination' value='"+sum_product+"'>";html=html+"<input type='hidden' id='limit_for_pagination' value='"+limit_product+"'>";if(!has_product){html+='<p class="text-center"> No products were found matching your selection.</p>';}
$("#collection-product").append(html);if(sum_product>=limit_product){$(".products-result-count").append(" Showing 1–"+limit_product+" of "+sum_product+" results");$(".pagination").append(pagination_html(limit_product,sum_product));}
else{$(".products-result-count").append(" Showing 1–"+sum_product+" of "+sum_product+" results");var view=$(".products-change-view.active").attr('data-view');$(".product-"+view+"-filter").show();$(".product-"+view+"-filter").addClass('view-'+view);if(view=='grid'){$(".product-list-filter").addClass('view-list');}else{$(".product-grid-filter").addClass('view-grid');}}
$(".active .page-number").trigger('click');var storage=$.localStorage;var compare={};var total_compare=4;if(storage.isSet('compare')){compare=storage.get('compare');}else{storage.set('compare',{});}
if(!$.isEmptyObject(compare)){var list_id='';var json_compare=[];var check_end=0;var compare_size=(Object.keys(compare).length-1);$.each(compare,function(index,el){$("[data-pid='"+el+"']").addClass('added');$("[data-pid='"+el+"']").attr("title","Added compare");$("[data-pid='"+el+"']").removeClass("compare");});}else{compare={};}
window.setTimeout(function(){$("#loading").hide(700);},1000);}});$(document).on('click','.page-number',function(event){var val=$(this).text();var view=$(".products-change-view.active").attr('data-view');$(".product-"+view+"-filter").hide();$(".product-"+view+"-filter").removeClass('view-'+view);$(".page-"+val+"-"+view).addClass('view-'+view);$(".page-"+val+"-"+view).show();var class_number=$(this).parent().attr('data-number');$(".page-number").parent().removeClass("active");$("."+class_number).addClass("active");var limit=$("#limit_for_pagination").val();var limitstart=(val-1)*limit+1;var limitend=val*limit;var sum=$("#sum_for_pagination").val();if(limitend>sum)limitend=sum;$(".products-result-count").empty();$(".products-result-count").append(" Showing "+limitstart+"–"+limitend+" of "+sum+" results");})
function pagination_html(limit,total){var i;var sum_page=Math.ceil(total/limit);var $html='';$html+='';for(i=1;i<=sum_page;i++){if(i==1){$html+='<div class="page-nav-item active number-page-'+i+'" data-number="number-page-'+i+'"><div class="page-number" id="pagination_'+i+'">'+i+'</div></div>';}else{$html+='<div class="page-nav-item number-page-'+i+'" data-number="number-page-'+i+'"><div class="page-number" id="pagination_'+i+'">'+i+'</div></div>';}}
$html+='';return $html;}
function json_to_html(product,view,check,page){var is_sale=false;if(product.compare_at_price>product.price){is_sale=true;}
var sold_out=true;if(product.available){sold_out=false;}
var $html='';$html+='<div class="product-box  page-'+page+'-grid product-'+view+'-filter">';$html+='<div class="product-box-content">';$html+='<figure class="img-product">';$html+='<img src="'+product.featured_image+'" alt="product" height="207" width="175">';$html+='<a href="/products/'+product.handle+'" class="flaticon-search quick-view" title="Quick View"></a>      ';$html+='</figure>';$html+='<div class="product-box-text">';$html+='<a href="/products/'+product.handle+'" class="product-name">'+product.title+'</a>';$html+='<div class="write">';$html+='<div class="star-rating rating">';$html+='<span class="shopify-product-reviews-badge" data-id="'+product.variants[0].id+'"></span>';$html+='</div>';$html+='</div>';$html+='<p class="product-cost">';if(is_sale==true){$html+='<ins><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency(product.variants[0].price,'xx')+'">'+convert_currency(product.variants[0].price,'xx')+'</span></ins>';}else{$html+='<ins><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency(product.variants[0].price,'xx')+'">'+convert_currency(product.variants[0].price,'xx')+'</span></ins>';}
$html+='</p>';$html+='<div class="desc-product">';$html+=readMore(product.content,25);$html+='</div>';if(sold_out==false){$html+='<div class="product-box-bottom">';if((product.variants).length>1){$html+='<a class="add-to-cart select-option" style="cursor:pointer;" onclick="location.href=/products/'+product.handle+'">Option</a>';if(is_customers){if(jQuery.inArray(product.id,json_wishlist)>=0){$html+='<a class="view-added-wishlist" title="View wishlist" href="/collections/wishlist"><i class="flaticon-like"></i></a>';}else{if(json_product_tag[check].indexOf("x")>=0){$html+='<a class="wishlist " title="Add to wishlist" href="javascript:void(0)" data-form="x'+json_product_tag[check]+'"><i class="flaticon-like"></i></a>';$html+='<a class="added-wishlist wishlist" href="/collections/wishlist" title="Added to wishlist" data-toggle="tooltip" data-placement="top" ><i class="flaticon-like"></i></a>';}else{$html+='<a class="wishlist" title="Add to wishlist" href="javascript:void(0)" data-form="'+json_product_tag[check]+'"><i class="flaticon-like"></i></a>';$html+='<a class="added-wishlist wishlist" href="/collections/wishlist" title="Added to wishlist" data-toggle="tooltip" data-placement="top" ><i class="flaticon-like"></i></a>';}}}else{$html+='<a class="wishlist-login wishlist add_to_wishlist" href="javascript:void(0)" title="Login to use wishlist"><i class="flaticon-like"></i></a>';}
$html+='<a href="javascript:void(0);" class="refresh-product compare" data-pid="'+product.handle+'" title="Add to Compare"><i class="flaticon-arrows"></i></a>';}else{if(product.variants[0].id){$html+='<a href="javascript:void(0);" class="add_to_cart_button add-to-cart" data-pid="'+product.variants[0].id+'"><i class="flaticon-commerce"></i>Add to cart</a>';if(is_customers){if(jQuery.inArray(product.id,json_wishlist)>=0){$html+='<a class="view-added-wishlist" title="View wishlist" href="/collections/wishlist"><i class="flaticon-like"></i></a>';}else{if(json_product_tag[check].indexOf("x")>=0){$html+='<a class="wishlist " title="Add to wishlist" href="javascript:void(0)" data-form="x'+json_product_tag[check]+'"><i class="flaticon-like"></i></a>';$html+='<a class="added-wishlist wishlist" href="/collections/wishlist" title="Added to wishlist" data-toggle="tooltip" data-placement="top" ><i class="flaticon-like"></i></a>';}else{$html+='<a class="wishlist" title="Add to wishlist" href="javascript:void(0)" data-form="'+json_product_tag[check]+'"><i class="flaticon-like"></i></a>';$html+='<a class="added-wishlist wishlist" href="/collections/wishlist" title="Added to wishlist" data-toggle="tooltip" data-placement="top" ><i class="flaticon-like"></i></a>';}}}else{$html+='<a class="wishlist-login wishlist add_to_wishlist" href="javascript:void(0)" title="Login to use wishlist"><i class="flaticon-like"></i></a>';}
$html+='<a href="javascript:void(0);" class="refresh-product compare" data-pid="'+product.handle+'" title="Add to Compare"><i class="flaticon-arrows"></i></a>';}}
$html+=' </div>';}else{$html+='<div class="product-box-bottom" style="text-align: center;">';$html+='<a class="btn-sold-out add-to-cart" onclick="location.href=/products/'+product.handle+'">SOLD OUT</a>';$html+='</div>';}
$html+='</div>';$html+='</div>';$html+='</div>';return $html;}
function makeid()
{var text="";var possible="abcdefghijklmnopqrstuvwxyz";for(var i=0;i<5;i++)
text+=possible.charAt(Math.floor(Math.random()*possible.length));return text;}});})(jQuery);